
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: all ease-out 0.2s;
    box-shadow: var(--shadow-elevation-1) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
    will-change: unset !important;
    &.v-btn:not(.v-btn--has-bg) {
        background-color: #fff !important;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .btn-top-primary {
        bottom: var(--btn-top-position-bottom) !important;
        right: var(--btn-top-position-right) !important;
    }
}
@media (min-width: 1200px) {
}
