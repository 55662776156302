
.footer {
    border-top: 1px solid var(--border-color);
    color: var(--v-grey-lighten1);
}
::v-deep{
    .v-btn.v-size--small:not(.v-btn--icon) {
        margin: 0 -12px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    ::v-deep{
        .v-btn.v-size--small:not(.v-btn--icon) {
            margin: 0 -16px;
        }
    }
}
@media (min-width:1200px){
}

